var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-1 d-flex justify-content-end align-items-center"},[(_vm.organizationRequiresEditConfirmation)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":_vm.$t('Edit'),"icon":_vm.isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated',"size":"36"},on:{"click":function($event){return _vm.isEditAble()}}}):_vm._e(),_c('div',{staticStyle:{"position":"relative"}},[_c('b-button',{staticClass:"font-weight-bolder",staticStyle:{"padding":"10px 7px"},attrs:{"disabled":_vm.isEdit},on:{"click":function($event){return _vm.navigateTo(_vm.routeID, $event, 'home-inventory-item-quote')}}},[_vm._v(" "+_vm._s(_vm.$t('PO'))+" ")])],1)],1),(!_vm.loader)?_c('l-table-list-collector',_vm._b({ref:"lTableRef",staticClass:"mt-3",attrs:{"is-search-input-disabled":_vm.isEdit,"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"fetched-data":_vm.returnProcurement(),"autosuggest-list-item-name":"cust_name_dba","table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getSuppliers"),
      isSortDirDesc: false
    },"table-suggestion-configs":_vm.autoSuggestTableColumns,"is-suggestion-table-head":true},on:{"getCollectedList":_vm.getCollectedList},scopedSlots:_vm._u([{key:"cell(action)",fn:function(ref){
    var item = ref.data.item;
return [_c('Can',{attrs:{"i":_vm.ACCESS_ABILITY_PROCUREMENT.action,"a":_vm.ACCESS_ABILITY_PROCUREMENT.subject}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticClass:"border-dashed btn p-0 mr-1",attrs:{"disabled":_vm.isEdit},on:{"click":function($event){return _vm.remove(item)}}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColor",staticStyle:{"padding":"4px","height":"26px"},attrs:{"icon":"LTrashIcon","size":"30"}})],1),_c('feather-icon',{staticClass:"cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor",staticStyle:{"padding":"4px","height":"26px"},attrs:{"icon":"LEyeIcon","size":"30"},on:{"click":function($event){return _vm.showProduct(item.id, $event)}}})],1)])]}},{key:"cell(supplier_sku)",fn:function(ref){return [_c('div',{staticClass:"d-inline-block w-100 text-center"},[_c('span',[_vm._v("—")])])]}},{key:"cell(last_rate)",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-block w-100 text-center"},[(item)?_c('span',[_vm._v(_vm._s(item))]):_c('span',[_vm._v("—")])])]}},{key:"cell(last_supplied)",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-block w-100 text-center"},[(item)?_c('span',[_vm._v(_vm._s(item))]):_c('span',[_vm._v("—")])])]}}],null,false,1450824446)},'l-table-list-collector',_vm.getTableProps(),false)):_vm._e(),(_vm.loader)?_c('div',[_c('b-table-skeleton',{attrs:{"fields":_vm.tableColumns}})],1):_vm._e(),_c('portal',{attrs:{"to":"body-footer"}},[_c('div',{staticClass:"d-flex mt-2 pb-1 justify-content-between"},[_c('div',[(!_vm.isFormChanged)?_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Back to List'))+" ")]):_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.loaderThis}},[_c('feather-icon',{attrs:{"icon":"LCancelIcon","size":"16"}}),_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")],1)],1),_c('div',[(_vm.$can(_vm.ACCESS_ABILITY_PROCUREMENT.action, _vm.ACCESS_ABILITY_PROCUREMENT.subject))?_c('b-button',{staticClass:"saveBtn font-medium-1 font-weight-bolder",attrs:{"variant":"success","disabled":!_vm.isFormChanged},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('SAVE'))+" ")]):_vm._e()],1)])]),_c('b-s-warning-modal',{ref:"confirm-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div
      class="mb-1 d-flex justify-content-end align-items-center"
    >
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="mr-1 cursor-pointer"
        @click="isEditAble()"
      />
      <div
        style="position: relative;"
      >
        <b-button
          class="font-weight-bolder"
          style="padding: 10px 7px"
          :disabled="isEdit"
          @click="navigateTo(routeID, $event, 'home-inventory-item-quote')"
        >
          {{ $t('PO') }}
        </b-button>
      </div>
    </div>
    <l-table-list-collector
      v-if="!loader"
      ref="lTableRef"
      :is-search-input-disabled="isEdit"
      v-bind="getTableProps()"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :fetched-data="returnProcurement()"
      autosuggest-list-item-name="cust_name_dba"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getSuppliers`,
        isSortDirDesc: false
      }"
      :table-suggestion-configs="autoSuggestTableColumns"
      :is-suggestion-table-head="true"
      class="mt-3"
      @getCollectedList="getCollectedList"
    >
      <template #cell(action)="{ data: { item } }">
        <Can
          :i="ACCESS_ABILITY_PROCUREMENT.action"
          :a="ACCESS_ABILITY_PROCUREMENT.subject"
        >
          <div class="d-flex align-items-center">
            <button
              class="border-dashed btn p-0 mr-1"
              :disabled="isEdit"
              @click="remove(item)"
            >
              <feather-icon
                icon="LTrashIcon"
                size="30"
                style="padding: 4px; height: 26px"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              />
            </button>
            <feather-icon
              icon="LEyeIcon"
              size="30"
              style="padding: 4px; height: 26px"
              class="cursor-pointer mr-1 border-dotted feather-trash-icon defaultIconColor"
              @click="showProduct(item.id, $event)"
            />
          </div>
        </Can>
      </template>
      <template
        #cell(supplier_sku)="{}"
        class="column-table"
      >
        <div class="d-inline-block w-100 text-center">
          <span>—</span>
        </div>
        <!--        <div class="d-inline-block w-100 text-center">-->
        <!--          <b-form-input-->
        <!--            v-if="data.item.id === saveItemId"-->
        <!--            :id="String(data.item.id)"-->
        <!--            v-model="getNeedObj(procurement.suppliers, data.item.id).sku"-->
        <!--            type="text"-->
        <!--            name="String(data.data.item.id)"-->
        <!--            style="width: 100px; height: 30px"-->
        <!--            class="d-inline-block text-center pr-0"-->
        <!--            @focusout="clickHandler(null)"-->
        <!--          />-->
        <!--          <div-->
        <!--            v-else-->
        <!--            class="d-inline-block text-center"-->
        <!--            @click="clickHandler(data.item.id)"-->
        <!--          >-->
        <!--            <span v-if="getNeedObj(procurement.suppliers, data.item.id).sku">-->
        <!--              {{ checkValue(getNeedObj(procurement.suppliers, data.item.id).sku) }}-->
        <!--            </span>-->
        <!--            <span-->
        <!--              v-else-->
        <!--              class="text-center"-->
        <!--            >-->
        <!--              &mdash;-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->
      </template>

      <template
        #cell(last_rate)="{item}"
        class="column-table"
      >
        <div class="d-inline-block w-100 text-center">
          <span
            v-if="item"
          >{{ item }}</span>
          <span
            v-else
          >&mdash;</span>
        </div>
      </template>

      <template
        #cell(last_supplied)="{item}"
      >
        <div class="d-inline-block w-100 text-center">
          <span
            v-if="item"
          >{{ item }}</span>
          <span
            v-else
          >&mdash;</span>
        </div>
      </template>

    </l-table-list-collector>
    <div
      v-if="loader"
    >
      <b-table-skeleton :fields="tableColumns" />
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="!isFormChanged"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="cancel"
          >
            {{ $t('Back to List') }}
          </b-button>
          <b-button
            v-else
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="loaderThis"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
        </div>
        <div>
          <b-button
            v-if="$can(ACCESS_ABILITY_PROCUREMENT.action, ACCESS_ABILITY_PROCUREMENT.subject)"
            variant="success"
            class="saveBtn font-medium-1 font-weight-bolder"
            :disabled="!isFormChanged"
            @click="submit"
          >
            {{ $t('SAVE') }}
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BButton, BFormInput, VBTooltip } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import store from '@/store'
import config from '../procurementConfig'

export default {
  name: 'ProcurementsListCollector',
  components: {
    BButton,
    BFormInput,
    LTableListCollector,
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fetchedItem: [],
      onSubmit: false,
      loader: false,
      saveItemId: null,
      routeID: this.$route.params.id,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    procurement() {
      return this.$store.state[this.MODULE_NAME].procurementForm
    },
    product() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
    procurementFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].productProcurementsClone
    },
    isFormChanged() {
      return JSON.stringify(this.procurement) !== this.procurementFormClone
    },
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  created() {
    this.getProcurement()
  },
  methods: {
    clickHandler(id) {
      this.saveItemId = id
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) {
        this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
      }
    },
    getNeedObj(arr, index) {
      // eslint-disable-next-line array-callback-return
      return arr.find(item => {
        if (item.customer && item.customer.id === index) {
          return item
        } if (item.customer_detail && item.customer_detail.id === index) {
          return item
        }
      })
    },
    checkValue(value) {
      return value || '---'
    },
    getCollectedList(list) {
      this.$store.commit(`${this.MODULE_NAME}/SET_PROCUREMENT_FORM`, {
        suppliers: list,
      })
    },
    cancel() {
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    loaderThis() {
      this.getProcurement()
    },
    returnProcurement() {
      return this.fetchedItem
    },
    getProcurement() {
      this.loader = true
      this.$store.dispatch(`${this.MODULE_NAME}/getProcurement`, this.$route.params?.id)
        .then(res => {
          this.loader = false
          const { data: { supplier } } = res.data

          this.fetchedItem = supplier.map(item => ({ ...item, ...item.customer }))

          this.$store.commit(`${this.MODULE_NAME}/SET_PROCUREMENT_FORM`, { suppliers: supplier })
          this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CATALOG_PRODUCT_PROCUREMENTS_CLONE`, { suppliers: supplier })
        })
        .catch(err => console.log(err))
    },
    submit() {
      const data = this.procurement
      const { name } = this.product
      this.onSubmit = true
      const mapData = data.suppliers?.map(item => ({
        id: item.customer ? item.customer.id : item.id,
        supplier_sku: item.sku,
        supplier_product_name: name,
      }))

      this.sendNotification(this, { suppliers: mapData }, `${this.MODULE_NAME}/updateProcurement`).then(() => {
        this.$router.push({
          name: 'home-catalog-substitutes',
          params: { id: this.product?.id },
        })
      })
        .catch(err => {
          this.error = err.response?.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: i18n.t(err.response?.data.message, { module: this.MODULE_NAME }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.getProcurement()
        })
    },
    remove(data) {
      // this.procurement.suppliers = this.fetchedItem.filter(supplier => supplier.id !== data.id)
      this.fetchedItem = this.fetchedItem.filter(supplier => supplier.id !== data.id)
      this.procurement.suppliers = this.fetchedItem
    },
    showProduct(id, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({ name: 'home-customers-view', params: { id } })
        window.open(route.href)
      } else {
        this.$router.push({ name: 'home-customers-view', params: { id } })
      }
    },
    navigateTo({ id }, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params: { inv_id: id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params: { inv_id: id },
        })
      }
    },
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: this.procurement,
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': true,
        'empty-text': this.$t('None found'),
        busy: this.isBusy,
        class: 'position-relative custom-procurement--table',
        striped: true,
        small: true,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return defaultTableProps
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'procurement'
    const CATALOG_MODULE_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'

    const { tableColumns, autoSuggestTableColumns, ACCESS_ABILITY_PROCUREMENT } = config()

    return {
      tableColumns,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      CATALOG_MODULE_NAME,
      autoSuggestTableColumns,
      ACCESS_ABILITY_PROCUREMENT,
    }
  },
}
</script>
<style scoped>
.btn-top {
  display: block;
  width: 36px;
  height: 36px;
  padding: 12px 7px;
  position: absolute;
  right: 20px;
  top: 24px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
</style>
<style lang="scss">
.custom-procurement--table {
  & tbody tr {
    height: 36px;
  }
  & tbody tr:nth-child(odd) {
    background-color: #FFFFFF;
  }
  & tbody tr:nth-child(even) {
    background-color: #FBFBFB;
  }

  & tbody tr:not(.b-table-empty-row) td {
    padding: 0 !important;

    &:not(:last-child) {
      border-bottom-right-radius: 0 !important;
    }

    &:first-child {
      padding-left: 8px !important;
    }
    &:last-child {
      padding-right: 8px !important;
    }
  }
}
</style>

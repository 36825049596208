<template>
  <procurements-list-collector ref="procurements-list-collector" />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import procurementModule from '@/store/main/catalog/procurement'
import ProcurementsListCollector from './components/ProcurementsListCollector.vue'

export default {
  name: 'Procurement',
  components: { ProcurementsListCollector },
  created() {
    this.$emit('updateMenu', 'home-catalog-procurement')
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['procurements-list-collector']) {
      this.$refs['procurements-list-collector'].showConfirmationModal(next)
    } else {
      next()
    }
  },
  setup() {
    const MODULE_NAME = 'procurement'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, procurementModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

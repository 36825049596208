import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_PROCUREMENT = { action: 'change', subject: 'Procurement' }
  // Table Handlers
  const tableColumns = [
    { key: 'action', label: '', thStyle: { width: can(ACCESS_ABILITY_PROCUREMENT.action, ACCESS_ABILITY_PROCUREMENT.subject) ? '10%' : '0%' } },
    {
      key: 'cust_name_dba',
      label: 'Company Name',
      thStyle: { paddingLeft: '0px', width: '300px' },
    },
    {
      key: 'default_contact.cell_no',
      label: 'Contact',
      thStyle: { paddingLeft: '0px' },
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
      thStyle: { textAlign: 'center' },
    },
    {
      key: 'last_rate',
      label: 'Last Rate',
      thStyle: { textAlign: 'center' },
    },
    {
      key: 'last_supplied',
      label: 'Last Supplied',
      thStyle: { textAlign: 'center' },
    },
  ]
  const autoSuggestTableColumns = [
    {
      key: 'cust_name_dba',
      label: '',
    },
    {
      key: 'cell_no',
      label: '',
      getValue: (value, data) => `${data.default_contact?.cell_no}`,
    },
  ]

  return {
    tableColumns,
    autoSuggestTableColumns,
    ACCESS_ABILITY_PROCUREMENT,
  }
}

export default {
  GET(state, data) {
    state.procurement = { ...data }
  },
  LIST(state, data) {
    state.procurements = data
  },
  SET_PROCUREMENT_FORM(state, data) {
    state.procurementForm = { ...data }
  },
}

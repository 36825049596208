import crudActions from '@/libs/storeConstActions'
import store from '@/store'
import axios from '@/libs/axios'
import { stringify } from 'qs'
// import axios from '@/libs/axios'

// const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/`
const endpoint = () => `products/${store.state.catalogs?.catalog?.id}/suppliers`

const updateProcurement = (ctx, data) => axios.put(endpoint(), data)

const getProcurement = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint())
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getSuppliers = ({ commit }, { per_page, search }) => new Promise((resolve, reject) => {
  axios.get('customers', {
    params: {
      search,
      f: {
        allow_to_purchase_from_supplier: '1',
        is_active: 1,
      },
      per_page,
    },
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  updateProcurement,
  getProcurement,
  getSuppliers,
}
